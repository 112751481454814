export const InputTypes = {
  Text: 'text',
  Password: 'password',
  Search: 'search',
  HiddenText: 'hidden_text',
  Email: 'email',
};

export const InputCases = {
  SnakeCase: 'snake_case',
  CamelCase: 'camelCase',
  KebabCase: 'kebab-case',
  Uppercase: 'upper-case',
};

export const FormTextAligns = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
};

export const FormStyles = {
  Solid: 'solid',
  Outline: 'outline',
  Naked: 'naked',
};

export const FormColors = {
  Violet: 'violet',
  Indigo: 'indigo',
  Blue: 'blue',
  Orange: 'orange',
  Cyan: 'cyan',
  Red: 'red',
  Green: 'green',
  GreenLight: 'green-light',
  Yellow: 'yellow',
  Black: 'black',
  White: 'white',
  Pink: 'pink',
  Gray: 'gray',
  GrayDark: 'gray-dark',
  Disabled: 'disabled',
};

export const FormThemes = {
  [FormColors.Violet]: {
    [FormStyles.Solid]: 'bg-violet-300 text-white hover:bg-violet-900 hover:text-white stroke-white border-violet-300 ',
    [FormStyles.Outline]: 'border bg-white border-violet-300 text-violet-700 hover:bg-violet-300 hover:text-white data-checked:bg-violet-300 data-checked:text-white stroke-violet-700 border-2',
    [FormStyles.Naked]: 'bg-transparent border-none text-violet-300 stroke-violet-300 hover:bg-violet-100',
  },
  [FormColors.GreenLight]: {
    [FormStyles.Solid]: 'bg-green-300 text-white hover:bg-green-900 hover:text-white stroke-white border-green-300 ',
    [FormStyles.Outline]: 'border bg-white border-green-300 text-green-700 hover:bg-green-300 hover:text-white data-checked:bg-green-300 data-checked:text-white stroke-green-700 border-2',
    [FormStyles.Naked]: 'bg-transparent border-none text-green-300 stroke-green-300 hover:bg-green-100',
  },
  [FormColors.Green]: {
    [FormStyles.Solid]: 'bg-green-600 text-white hover:bg-green-800 hover:text-white',
    [FormStyles.Outline]: 'border bg-transparent border-green-600 text-green-600 hover:bg-green-50',
    [FormStyles.Naked]: 'bg-transparent border-none text-green-800 stroke-green-800 hover:bg-green-100',
  },
  [FormColors.Orange]: {
    [FormStyles.Solid]: 'bg-orange-300 text-white hover:bg-orange-900 hover:text-white stroke-white border-orange-300 ',
    [FormStyles.Outline]: 'border bg-white border-orange-300 text-orange-700 hover:bg-orange-300 hover:text-white data-checked:bg-orange-300 data-checked:text-white stroke-orange-700 border-2',
    [FormStyles.Naked]: 'bg-transparent border-none text-orange-300 stroke-orange-300 hover:bg-orange-100',
  },
  [FormColors.Indigo]: {
    [FormStyles.Solid]: 'bg-indigo-700 text-white hover:bg-indigo-900 hover:text-white stroke-white border-indigo-700 ',
    [FormStyles.Outline]: 'border bg-white border-indigo-700 text-indigo-700 hover:bg-indigo-100 data-checked:bg-indigo-700 data-checked:text-white stroke-indigo-700',
    [FormStyles.Naked]: 'bg-transparent border-none text-indigo-700 stroke-indigo-700 hover:bg-indigo-50 data-checked:bg-indigo-50',
  },
  [FormColors.Blue]: {
    [FormStyles.Solid]: 'bg-blue-700 text-white hover:bg-blue-900 hover:text-white stroke-white border-blue-700',
    [FormStyles.Outline]: 'border bg-white border-blue-300 text-blue-300 hover:bg-blue-300 hover:text-white data-checked:bg-blue-300 data-checked:text-white stroke-blue-300 [&_svg]:stroke-[3px] border-2',
    [FormStyles.Naked]: 'bg-transparent border-none text-blue-300 stroke-blue-300 hover:bg-blue-50 data-checked:bg-blue-50 [&_svg]:stroke-[3px]',
  },
  [FormColors.Cyan]: {
    [FormStyles.Solid]: 'bg-cyan-400 text-white hover:bg-cyan-900 hover:text-white stroke-white',
    [FormStyles.Outline]: 'border bg-cyan-50 border-cyan-500 text-cyan-600 hover:bg-cyan-500 hover:text-white stroke-cyan-800',
    [FormStyles.Naked]: 'bg-transparent border-none text-cyan-600 stroke-cyan-600 hover:bg-cyan-100 data-checked:bg-cyan-600 data-checked:text-white',
  },
  [FormColors.Red]: {
    [FormStyles.Solid]: 'bg-red-800 text-white hover:bg-red-900 hover:text-white stroke-white fill-white',
    [FormStyles.Outline]: 'border bg-transparent border-red-800 text-red-800 hover:bg-red-50',
    [FormStyles.Naked]: 'bg-transparent border-none text-red-800 stroke-red-800 hover:bg-red-100',
  },
  [FormColors.Yellow]: {
    [FormStyles.Solid]: 'bg-amber-100 text-slate-700 hover:bg-amber-200',
    [FormStyles.Outline]: 'border bg-transparent border-amber-200 text-slate-700 hover:bg-amber-200',
    [FormStyles.Naked]: 'bg-transparent border-none text-amber-300 stroke-amber-300 hover:bg-amber-100',
  },
  [FormColors.Black]: {
    [FormStyles.Solid]: 'bg-gray-500 text-white hover:bg-gray-900 hover:text-white fill-white stroke-white',
    [FormStyles.Outline]: 'border bg-transparent border-gray-400 text-gray-800 hover:bg-gray-100 data-checked:bg-gray-200',
    [FormStyles.Naked]: 'bg-transparent border-none text-black stroke-inherit hover:bg-gray-200 data-checked:bg-gray-200',
  },
  [FormColors.White]: {
    [FormStyles.Solid]: 'bg-white text-black hover:bg-gray-200 hover:text-black fill-black stroke-inherit',
    [FormStyles.Outline]: 'border bg-transparent border-white text-black hover:bg-gray-400 hover:text-black',
    [FormStyles.Naked]: 'bg-transparent border-none text-white stroke-white hover:bg-gray-200 hover:bg-opacity-50 fill-white stroke-inherit',
  },
  [FormColors.Pink]: {
    [FormStyles.Solid]: 'bg-cyan-400 text-white hover:bg-cyan-200 fill-white stroke-white',
    [FormStyles.Outline]: 'border bg-transparent border-white text-black hover:bg-gray-400 hover:text-black',
    [FormStyles.Naked]: 'bg-transparent border-none text-white stroke-white hover:bg-gray-200 hover:bg-opacity-50 fill-white stroke-inherit',
  },
  [FormColors.Gray]: {
    [FormStyles.Solid]: 'bg-indigo-50 text-black hover:bg-indigo-100 stroke-white',
    [FormStyles.Outline]: 'border bg-white border-slate-200 text-slate-700 hover:bg-slate-200 stroke-slate-700',
    [FormStyles.Naked]: 'bg-transparent border-none text-slate-300 stroke-slate-300 hover:bg-slate-100',
  },
  [FormColors.GrayDark]: {
    [FormStyles.Solid]: 'bg-slate-500 text-white hover:bg-slate-900 hover:text-white stroke-white',
    [FormStyles.Outline]: 'border bg-white border-slate-500 text-slate-700 hover:bg-slate-500 hover:text-white data-checked:bg-slate-500 data-checked:text-white stroke-slate-700',
    [FormStyles.Naked]: 'bg-transparent border-none text-slate-500 stroke-slate-500 hover:bg-slate-100',
  },
  [FormColors.Disabled]: {
    [FormStyles.Solid]: 'bg-gray-400 text-white fill-white stroke-white opacity-50',
    [FormStyles.Outline]: 'border bg-transparent border-gray-400 text-gray-400 opacity-50',
    [FormStyles.Naked]: 'bg-transparent border-none text-gray-500 stroke-gray-500 opacity-50',
  },
};

export const FormBorderStyles = {
  Rounded: 'rounded',
  RoundedFull: 'rounded-full',
  Straight: 'rounded-none',
  StraightLeft: 'rounded rounded-l-none',
  StraightRight: 'rounded rounded-r-none',
  StraightTop: 'rounded rounded-t-none',
  OnlyBottom: 'border-0 border-b border-solid',
};

export const ButtonSizes = {
  Default: 'default',
  Mini: 'mini',
  Big: 'big',
  Custom: 'custom',
};

export const MenuButtonAligns = {
  Left: 'left',
  Right: 'right',
};

export const TooltipColors = {
  Red: 'red',
  Black: 'black',
  White: 'white',
};

export const TooltipThemes = {
  [TooltipColors.Red]: 'bg-red-800 text-white',
  [TooltipColors.Black]: 'bg-black text-white',
  [TooltipColors.White]: 'bg-white text-black border border-black',
};

export const CheckTypes = {
  Checkbox: 'checkbox',
  RadioButton: 'radio',
  Toggle: 'toggle',
};

export const MultipleSelectionDirection = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
};

export const PrimitiveDataTypes = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
  Email: 'email',
  UUID: 'uuid',
  Date: 'date',
};

export const ComparatorTypes = {
  Equal: '==',
  Greater: '>',
  Smaller: '<',
  NotEqual: '!=',
  NotGreater: '<=',
  NotSmaller: '>=',
};

export const DataTypeComparators = {
  Default: [ComparatorTypes.Equal, ComparatorTypes.NotEqual],
  [PrimitiveDataTypes.UUID]: [ComparatorTypes.Equal, ComparatorTypes.NotEqual],
  [PrimitiveDataTypes.String]: [ComparatorTypes.Equal, ComparatorTypes.NotEqual],
  [PrimitiveDataTypes.Boolean]: [ComparatorTypes.Equal, ComparatorTypes.NotEqual],
  [PrimitiveDataTypes.Number]: [
    ComparatorTypes.Equal, ComparatorTypes.NotEqual,
    ComparatorTypes.Greater, ComparatorTypes.NotGreater,
    ComparatorTypes.Smaller, ComparatorTypes.NotSmaller,
  ],
};

export const MagicFillTypes = {
  Heuristic: 'heuristic',
  Generative: 'generative',
  Mixed: 'mixed',
};
