import { createApp } from 'vue';

import { router } from './router_NEW';

import useAnalytics from './composables_NEW/useAnalytics';

import { isProd } from './constants/common';

import Main from './Main_NEW.vue';

import './styles/index.css';

const { initAnalytics } = useAnalytics();

const app = createApp(Main);

// Initialize analytics
const gtmObj = initAnalytics({
  appObj: app,
  isProd,
  router,
});


app.use(router);
app.use(gtmObj);


app.mount('#main');
