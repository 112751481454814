import isEqual from 'lodash.isequal';

import pako from 'pako';

import { PrimitiveDataTypes } from '@/constants/forms';
import { ArtifactFieldLiterals, ArtifactTypeLiterals } from '@/constants/artifacts';

export function isMobile() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

export function toCamelCase (str) {
  const tempStr = str.replace(/[-_\s]+(.)?/g, (_match, ch) => (ch !== '' ? ch.toUpperCase() : '')).normalize('NFD').replace(/\p{Diacritic}/gu, '');
  return tempStr.substring(0, 1).toLowerCase() + tempStr.substring(1);
}

export function toKebabCase (str = '') {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function toUpperCase (str = '') {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '').toUpperCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function toSnakeCase (str = '') {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').replace(/[\s-_+]+/g, '_').toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function isObject(ref) {
  return typeof ref === 'object' && !Array.isArray(ref) && ref !== null;
}

export function getErrorMessage (error) {
  if (error instanceof Error) return error.message || 'unknown error';
  return String(error);
}

export function appendPx (num = 0) {
  return `${num}px`;
}

export function isNumber(value) {
  return typeof value === 'number';
}

export function sortItemsByCreatedAt(itemsObj, filter = false) {
  const itemIds = Object.keys(itemsObj).filter(itemId => !filter || !!itemsObj[itemId].created_at);
  return itemIds.length ? itemIds.sort((itemId1, itemId2) => {
    const created1 = itemsObj[itemId1].created_at;
    const created2 = itemsObj[itemId2].created_at;
    if (!created1) {
      return -1;
    }
    const date1 = new Date(created1);
    const date2 = new Date(created2);
    return date2 - date1;
  }) : [];
}

export function sortItemsByUpdatedAt(itemsObj, filter = false) {
  const itemIds = Object.keys(itemsObj).filter(itemId => !filter || !!itemsObj[itemId].updated_at);
  return itemIds.length ? itemIds.sort((itemId1, itemId2) => {
    const date1 = new Date(itemsObj[itemId1].updated_at);
    const date2 = new Date(itemsObj[itemId2].updated_at);
    return date2 - date1;
  }) : [];
}

export function isEmptyData(dataObj) {
  return dataObj == null
    || dataObj === ''
    || (dataObj.length && dataObj.length === 0)
    || Object.keys(dataObj) && Object.keys(dataObj).length === 0;
}

export function samePosition(positionA, positionB) {
  return (positionA || []).join() === (positionB || []).join();
}

export function getParamNamesObject(paramsObj) {
  return paramsObj.reduce((optionsObj, propData) => {
    if (propData.type === PrimitiveDataTypes.Object) {
      if (propData.params) {
        const propParamNames = getParamNamesObject(propData.params);
        Object.keys(propParamNames).forEach(propParamName => {
          const propName = `${propData.name}.${propParamName}`;
          optionsObj[propName] = propName;
        });
      }
    } else {
      optionsObj[propData.name] = propData.name;
    }
    return optionsObj;
  }, {});
}

export function prettyPrintParams(params = []) {
  const paramsHtml = params.map(paramData => {
    const paramType = (paramData.type || PrimitiveDataTypes.String).toLowerCase();

    if([PrimitiveDataTypes.Object].includes(paramType)) {
      const objSymbolIn = paramData.is_array ? '[{' : '{';
      const objSymbolOut = paramData.is_array ? '}]' : '}';
      
      return `
        <div>
          <div class="font-medium">${paramData.name}${paramData.required ? '<sup>*</sup>' : ''}: ${paramData.params?.length ? `${objSymbolIn}</div> 
          <div class="pl-5">${prettyPrintParams(paramData.params)}</div>
          <div>${objSymbolOut}`: `${objSymbolIn}${objSymbolOut}`},</div> 
        </div>
      `;
    }
    return `
      <div>
        <span class="font-medium">${paramData.name}${paramData.required ? '<sup>*</sup>' : ''}: </span>
        <span class="font-light text-indigo-800">${paramType}${paramData.is_array ? '[];' : ';'}</span>
        ${paramData.comment ? `<span class="text-red-800 text-xs">${paramData.comment}</span>` : ''}
      </div>
    `;
  });
  return paramsHtml.join('');
}

export function prettyPrintParamsToExport(params = []) {
  const paramsStr = params.map(paramData => {
    const paramName = `${paramData.name}${paramData.required ? '*' : ''}`;

    if([PrimitiveDataTypes.Object].includes(paramData.type)) {
      const objSymbolIn = paramData.is_array ? '[{' : '{';
      const objSymbolOut = paramData.is_array ? '}]' : '}';
      
      return `${paramName}: ${paramData.params?.length ? `${objSymbolIn}${prettyPrintParamsToExport(paramData.params)}${objSymbolOut}`: `${objSymbolIn}${objSymbolOut}`} `;
    }
    return `${paramName}: ${paramData.type}${paramData.is_array ? '[];' : ';'} `;
  });
  return paramsStr.join('');
}

export function getUrlPathFromUrlObject(urlObject) {
  if (!urlObject) {
    return null;
  }
  
  const {
    Host,
    Path,
    Scheme,
  } = urlObject;

  return `${Scheme}://${Host}${Path}`;
}

export function prettyPrintObject(dataObj = {}) {
  const dataHtml = Object.keys(dataObj).map(key => {
    return `
      <div class="flex">
        <div class="font-medium whitespace-nowrap">${key}:</div>
        <div class="pl-2 font-light">
          ${ isObject(dataObj[key]) ? prettyPrintObject(dataObj[key]) : dataObj[key]}
        </div>
      </div>
    `;
  });
  return dataHtml.join('');
}

export function getElementScale(element) {
  let parentEl = element;
  let zoom = getComputedStyle(parentEl).scale === 'none' ? 1 : getComputedStyle(parentEl).scale;

  while (parentEl.parentElement && parentEl !== document.body) {
    parentEl = parentEl.parentElement;
    zoom = zoom * (getComputedStyle(parentEl).scale === 'none' ? 1 : getComputedStyle(parentEl).scale);
  }

  return zoom;
}

export function removeEmpty(obj) {
  const newObj = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (v === Object(v)) {
      newObj[k] = removeEmpty(v);
    } else if (v != null) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function deepEqualData(dataA, dataB, ignoreParams = []) {
  if (dataA && dataB) {
    const dataAclean = removeEmpty(dataA);
    const dataBclean = removeEmpty(dataB);

    ignoreParams.forEach(paramName => {
      delete dataAclean[paramName];
      delete dataBclean[paramName];
    });
    
    const response = isEqual(dataAclean, dataBclean);

    return response;
  } else if (dataA || dataB) {
    return false;
  }
  return true;
}

export function arraysHaveSameValues(arrA = [], arrB = []) {
  if (!Array.isArray(arrA) || !Array.isArray(arrB)) {
    return arrA === arrB;
  }
  
  const arrACopy = arrA.map(item => item).sort((a, b) => a.localeCompare(b));
  const arrbBCopy = arrB.map(item => item).sort((a, b) => a.localeCompare(b));

  return arrACopy.join(',') === arrbBCopy.join(',');
}

export function parseJwt(token = '') {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    return {};
  }
}

export function checkTokenExpired(token) {
  const parsedToken = parseJwt(token);
  return parsedToken.exp < Math.round(Date.now()/1000);
}

export function timeUntilDate(date, dateNow = new Date()) {
  const dateFrom = new Date(date);

  let seconds = Math.floor((dateNow - dateFrom)/1000);
  let minutes = Math.floor(seconds/60);
  let hours = Math.floor(minutes/60);
  const days = Math.floor(hours/24);

  hours = hours-(days*24);
  minutes = minutes-(days*24*60)-(hours*60);

  if (days) {
    return days > 365 ? '>1y' : `${days}d`;
  } else if (hours) {
    return `${hours}h`;
  } else if (minutes) {
    return `${minutes}m`;
  } else {
    return '<1m';
  }
}

export function uncompressData(compressedData) {
  const compressedBytes = Uint8Array.from(window.atob(compressedData), c => c.charCodeAt(0));
  const uncompressedBytes = pako.inflate(compressedBytes);
  
  return JSON.parse(new TextDecoder().decode(uncompressedBytes));
}

export function arrayMove(arr, oldIndex, newIndex) {
  const arrClone = arr.map(item => item);
  if (newIndex >= arrClone.length) {
    let k = newIndex - arrClone.length + 1;
    while (k--) {
      arrClone.push(undefined);
    }
  }
  arrClone.splice(newIndex, 0, arrClone.splice(oldIndex, 1)[0]);
  return arrClone;
}

const testIds = [];

export function getNewTestId(componentId) {
  const tryNum = testIds.filter(id => id.indexOf(componentId) === 0)?.length || 0;
  const newTestId = `${componentId}-${tryNum}`;

  testIds.push(newTestId);
  
  return newTestId;
}

export function uniq(a) {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for(var i = 0; i < len; i++) {
    var item = a[i];
    if(seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
}

export function parseJsonStringArray(jsonString) {
  try {
    return JSON.parse(jsonString || '[]');
  } catch (error) {
    if (jsonString) {
      return jsonString.split('.').map(item => item.trim());
    }
    return [];
  }
}

export function isUUID(uuid) {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getHumanReadableField = (field) => field ? ArtifactFieldLiterals[field] || field.replace(/_/g, ' ') : '';
export const getHumanReadableArtifactType = (artifactType) => artifactType ? ArtifactTypeLiterals[artifactType] || artifactType.replace(/_/g, ' ') : '';
export const getPlaceholder = (field) => `add here ${getHumanReadableField(field)}`;
