import { ArtifactDataModel, ArtifactFieldBases, noEditableArtifactFields } from '@/constants/artifacts';

export function isFolderField(field) {
  return field.base === ArtifactFieldBases.Artifact && !field._is_array && !field._is_reference;
}

export function filterArtifactFields(artifactType, {base, type, _is_array, _is_reference}) {
  const artifactDataModel = ArtifactDataModel[artifactType]?.fields;
  const editableFields = Object.keys(artifactDataModel || {}).filter(field => !noEditableArtifactFields.includes(field));
  const isNotFilter = (value) => value === null || value === undefined;

  const filteredFields = (editableFields || []).filter(field => {
    const {base: refBase, type: refType, _is_array: refIsArray, _is_reference: refIsReference} = artifactDataModel[field];
    return (isNotFilter(base) || base === refBase)
      && (isNotFilter(type) || type === refType)
      && (isNotFilter(_is_array) || !!_is_array === !!refIsArray)
      && (isNotFilter(_is_reference) || !!_is_reference === !!refIsReference);
  });

  return filteredFields;
}

export function getParentArtifactField(artifactType) {
  const parentArtifactField = Object.keys(ArtifactDataModel).map(type => {
    const relatedFields = filterArtifactFields(type, {base: ArtifactFieldBases.Artifact, _is_reference: false, type: artifactType});
    return relatedFields?.length ? {
      type,
      field: relatedFields[0],
    } : null;
  }).find(field => field);

  return parentArtifactField;
}

export function getReferenceOnArtifactTypes(artifactType) {
  return Object.entries(ArtifactDataModel).map(([type, {fields}]) => ({
    type,
    fields: Object.keys(fields || {}).filter(field => fields[field]._is_reference && fields[field].type === artifactType),
  })).filter(({fields}) => fields?.length);
}

