<script setup>
import { ref, computed, watch, useSlots } from 'vue';

import Sortable from 'sortablejs';

import useLanguages from '@/composables_NEW/useLanguages';
import useApplications from '@/composables_NEW/useApplications';
import useWorkspaces from '@/composables_NEW/useWorkspaces';

import UpgradePopup from '@/components_NEW/application/UpgradePopup.vue';
import GlueMenuButton from '@/components_NEW/formFields/GlueMenuButton.vue';
import Icon from './Icon.vue';
import GlueMultipleSelection from '@/components_NEW/formFields/GlueMultipleSelection.vue';
import LinksCell from '@/components_NEW/table/LinksCell.vue';
import ListCell from '@/components_NEW/table/ListCell.vue';
import TextCell from '@/components_NEW/table/TextCell.vue';

import { ButtonSizes, FormColors, FormStyles, MultipleSelectionDirection } from '@/constants/forms';
import { TableColumnTypes } from '@/constants/tables';
import { toSnakeCase } from '@/utils/utils';
import { convertToXlsxCsv } from '@/utils/export';
import { DataAssetTypeLiterals } from '@/constants/dataAssets';
import { SubscriptionTypeLimits } from '@/constants/subscription';

const emit = defineEmits(['sortItem']);

const props = defineProps({
  allowExport: Boolean,
  allowSort: Boolean,
  label: String,
  exportFile: String,
  dataType: String,
  groupByDefault: String,
  rowId: {
    type: String,
    default: 'id',
  },
  columns: {
    type: Array,
    default: () => [],
  },
  data: {
    type: Array,
    default: () => [],
  },
});

const { t } = useLanguages();
const slots = useSlots();

const { activeApplication } = useApplications();
const { activeWorkspace } = useWorkspaces();

const minimized = ref();
const fieldToGroupBy = ref([props.groupByDefault]);
const upgradePopupRef = ref();
const containerDom = ref();

const hasPermissionToExport = computed(() => !!SubscriptionTypeLimits[activeWorkspace.value?.subscription_type].allowExport);
const columnsAllowedToGroup = computed(() => props.columns.filter(column => column.allowGroup));
const columnsToShow = computed(() => props.columns.filter(column => ![TableColumnTypes.Hidden, TableColumnTypes.OnlyExport].includes(column.type) && column.field !== columnToGroupBy.value?.field));
const showHeader = computed(() => !!(props.allowExport || props.label || columnsAllowedToGroup.value.length || slots.header));
const columnToGroupBy = computed(() => props.columns.find(columnData => columnData.field === fieldToGroupBy.value?.[0]));
const exportFileName = computed(() => toSnakeCase(`${activeApplication.value?.name}.${DataAssetTypeLiterals[props.dataType]?.[1] || 'export data'}.${new Date().toISOString().slice(0, 10)}`));

const exportData = computed(() => {
  const dataToExport = props.data.map(item => props.columns
    .filter(column => !column.doNotExport && ![TableColumnTypes.Actions, TableColumnTypes.Hidden, TableColumnTypes.Icon].includes(column.type))
    .reduce((propsObj, column) => {
      let value = item[column.field];

      if (column.type === TableColumnTypes.List) {
        value = value.map(item => item?.text || item).join(', ');
      } else if (column.type === TableColumnTypes.Links) {
        value = value.map(link => `${link.label || ''}${link.description ? ` - ${link.description}` : '' }`).join(', ');
      } 

      return {
        ...propsObj,
        [column.label]: value,
      };
    }, {}),
  );
  return dataToExport;
});

const groupsOptions = computed(() => props.columns.filter(column => column.allowGroup)
  .reduce((columnsObj, column) => ({
    ...columnsObj,
    [column.groupButtonLabel]: column.field,
  }), {}),
);

const groups = computed(() => {
  if (minimized.value) {
    return [];
  }

  const isArrayColumn = [TableColumnTypes.List, TableColumnTypes.Links].includes(columnToGroupBy.value?.type);

  const groupableValues = columnToGroupBy.value ? [...props.data.reduce((valuesSet, rowData) => {
    if (isArrayColumn) {
      (rowData[columnToGroupBy.value.field] || []).forEach(data => data && valuesSet.add(data.label || data.text || data));
    } else {
      valuesSet.add(rowData[columnToGroupBy.value.field]?.label || rowData[columnToGroupBy.value.field]?.text || rowData[columnToGroupBy.value.field] || columnToGroupBy.value.noGroupLabel);
    }
    return valuesSet;
  }, new Set([columnToGroupBy.value.noGroupLabel]))] : [''];

  const groups = groupableValues.map(groupValue => {
    const rows = props.data.reduce((rowsArray, rowData) => {
      const groupCellData = columnToGroupBy.value ? !isArrayColumn ? (rowData[columnToGroupBy.value.field]?.text || rowData[columnToGroupBy.value.field]?.label || rowData[columnToGroupBy.value.field]) : rowData[columnToGroupBy.value.field].map(value => value.label || value.text || value) : null;
      if (
        groupableValues.length === 1
        || groupValue === columnToGroupBy.value.noGroupLabel && !groupCellData?.length
        || groupValue !== columnToGroupBy.value.noGroupLabel && (isArrayColumn ? groupCellData.includes(groupValue) : groupCellData === groupValue)
      ) {
        rowsArray.push({
          ...columnsToShow.value.reduce((cellsObj, column) => {
            cellsObj[column.field] = rowData[column.field];
            return cellsObj;
          }, {}),
          id: rowData[props.rowId],
        });
      }
      return rowsArray;
    }, []);

    return {
      label: groupValue?.label || groupValue?.text || groupValue,
      rows,
    };
  })
    .filter(group => group.rows.length)
    .sort((groupA, groupB) => groupB.label === columnToGroupBy.value.noGroupLabel ? 1 : (groupA.label || '').localeCompare(groupB.label || ''));

  return groups;
});

function onClickExportButton(type) {
  if (!hasPermissionToExport.value) {
    upgradePopupRef.value?.open();
    return;
  }
  convertToXlsxCsv(exportData.value, exportFileName.value, type);
}

watch(containerDom, () => {
  if (!props.allowSort || !containerDom.value) {
    return;
  }

  Sortable.create(containerDom.value, {
    handle: '.drag-icon',
    animation: 150,
    onEnd: (ev) => {
      emit('sortItem', ev);
    },
  });
});
</script>

<template>
  <div class="relative w-full max-h-full max-w-full text-sm">
    <div v-if="showHeader" class="top-0 left-0 z-20 flex gap-2 items-center px-4 py-4 min-h-12 bg-white rounded-sm border-slate-200 border border-b-0">
      <span v-if="label" class="text-base font-semibold leading-5">{{ label }}</span>
      <div class="flex sm:items-center flex-col sm:flex-row flex-grow sm:gap-2">
        <GlueMultipleSelection
          class="flex-grow"
          v-if="data.length"
          :options="groupsOptions"
          :allow-multiple="false"
          :direction="MultipleSelectionDirection.Horizontal"
          v-model="fieldToGroupBy"
        />
      </div>
      <slot name="header"/>
      <GlueMenuButton
        v-if="allowExport"
        icon="arrow-circle-up-right"
        :label="t('Export')"
        :items="{
          [t('Export to Excel')]: 'xlsx',
          [t('Export to CSV')]: 'csv',
        }"
        :color="FormColors.Cyan"
        :style="FormStyles.Outline"
        :size="ButtonSizes.Big"
        :hide-arrow="true"
        @click-menu-item="onClickExportButton"
      />
    </div>
    <div class="px-4 flex flex-col pt-4 pb-8 bg-white rounded-sm border-slate-200 border"
      :class="[
        showHeader ? 'border-t-0' : '',
      ]"
    >
      <table class="table-auto border-spacing-0">
        <thead class="border-b border-gray-300">
          <tr>
            <th v-if="allowSort && groups.length === 1" class="w-10" />
            <th
              v-for="({label, field, icon, centered, type}, columnIndex) in columnsToShow"
              :key="field"
              class="text-sm font-semibold text-slate-500"
              :class="[
                centered ? 'text-center' : 'text-left',
                showHeader ? 'top-12' : 'top-0',
                columnIndex === 0 ? 'pl-0' : 'pl-2',
                type === TableColumnTypes.Actions && 'w-2',
              ]"
            >
              <Icon v-if="icon" class="w-5 h-auto mr-1" :icon="icon"/>
              <span>{{ label }}</span>
            </th>
          </tr>
        </thead>
        <tbody ref="containerDom">
          <template
            v-for="({label, rows}) in groups"
            :key="label"
          >
            <tr v-if="label">
              <td :colspan="columnsToShow.length + (allowSort && groups.length === 1 ? 1 : 0)" class="">
                <div class="text-xl flex items-center bg-slate-100 p-2">
                  <Icon v-if="columnToGroupBy?.icon" class="w-5 h-auto mr-2" :icon="columnToGroupBy.icon"/>
                  <div class="flex items-baseline gap-2">
                    <span class="text-lg" v-html="label"/>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              v-for="(rowContent, rowIndex) in rows"
              :key="rowContent.id"
              class="border-b border-gray-200 align-top"
            >
              <td v-if="allowSort && groups.length === 1" class="py-4">
                <Icon class="drag-icon w-6 h-auto cursor-grab" icon="drag_list" />
              </td>
              <td
                v-for="({type, field, icon, centered}, columnIndex) in columnsToShow"
                :key="field"
                class="text-sm text-gray-600 py-4"
                :class="[
                  columnIndex === 0 ? 'pl-0' : 'pl-2',
                  rowIndex === rows.length - 1 ? 'border-none' : 'border-b',
                ]"
              >
                <LinksCell
                  v-if="[TableColumnTypes.Links, TableColumnTypes.Actions].includes(type)"
                  :icon="icon"
                  :centered="centered"
                  :content="rowContent[field]"
                  :type="type"
                />
                <ListCell
                  v-else-if="type === TableColumnTypes.List"
                  :icon="icon"
                  :centered="centered"
                  :content="rowContent[field]"
                  :type="type"
                />
                <TextCell
                  v-else
                  :icon="icon"
                  :centered="centered"
                  :content="rowContent[field]"
                  :type="type"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>  
    <UpgradePopup ref="upgradePopupRef"/>
  </div>
</template>

<style lang="postcss">
@keyframes table-item {
  0% {
    transform: translateX(-1000px);
    max-height: 0;
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    max-height: 500px;
    opacity: 1;
  }
}

.table-item {
  &-move {
    position: absolute;
    z-index: 10;
    animation: table-item 0.37s;
  }

  &-enter-active {
    z-index: 10;
    animation: table-item 0.37s;
  }

  &-leave-active {
    z-index: 0;
    animation: table-item 0.37s 0.2s reverse;
  }
}

.sortable-drag,
.sortable-fallback {
  opacity: 0 !important;
}

.sortable-chosen,
.sortable-ghost {
  @apply bg-cyan-50;
}

</style>
