import {artifacts as ArtifactDataModel} from '@/dataModel/artifactsDataModel.json';
import { RouteNames } from '@/constants/common';

export {ArtifactDataModel};

export const ArtifactTypes = {
  Actor: 'actor',
  Application: 'application',
  Activity: 'activity',
  Brief: 'brief',
  QuestionAnswer: 'question_answer',
  Canvas: 'canvas',
  Goals: 'goals',
  FunctionalRequirement: 'functional_requirement',
  NonFunctionalRequirement: 'non_functional_requirement',
  CustomerJourney: 'customer_journey',
  Feature: 'feature',
  UseCase: 'use_case',
  Note: 'note',
  UserStory: 'user_story',
  HumanInteraction: 'human_interaction',
  FrontendService: 'front_end_service',
  FrontendMethod: 'front_end_method',
  BackendService: 'back_end_service',
  BackendMethod: 'back_end_method',
  Insight: 'insight',
  File: 'file',
  Problem: 'problem',
  CustomerSegment: 'customer_segment',
  KeyMetric: 'key_metric',
  Solution: 'solution',
  UniqueValueProposition: 'unique_value_proposition',
  UnfairAdvantage: 'unfair_advantage',
  Channel: 'channel',
  RevenueStream: 'revenue_stream',
  CostStructure: 'cost_structure',
  UserGoal: 'user_goal',
  BusinessGoal: 'business_goal',
  NoGoal: 'no_goal',
  Authorization: 'authorization',
  DataProcessing: 'data_processing',
  UxUi: 'ux_ui',
  Reporting: 'reporting',
  SystemIntegration: 'system_integration',
  TransactionHandling: 'transaction_handling',
  ErrorHandling: 'error_handling',
  BackupAndRecovery: 'backup_recovery',
  Authentication: 'authentication',
  UsabilityAndAccessibility: 'usability_and_accessibility',
  Security: 'security',
  Reliability: 'reliability',
  Performance: 'performance',
  Availability: 'availability',
  Scalability: 'scalability',
  Standards: 'standards',
  Technologies: 'technologies',
  Architecture: 'architecture',
  Scenario: 'scenario',
  Event: 'event',
  Param: 'param',
  BackendFlow: 'back_end_flow',
  BackendFlowNode: 'back_end_flow_node',
  BackendFlowEdge: 'back_end_flow_edge',
  EdgeCondition: 'edge_condition',
  FrontendFlow: 'front_end_flow',
  Workpackage: 'workpackage',
  Risk: 'risk',
  View: 'view',
  TempVendor: 'temp_vendor',
  AcceptanceCriteria: 'acceptance_criteria',
};

export const ArtifactTypeLiterals = {
  customer_segment: 'Customer Segment',
  key_metric: 'Key Metric',
  unique_value_proposition: 'Unique Value Proposition',
  unfair_advantage: 'Unfair Advantage',
  revenue_stream: 'Revenue Stream',
  cost_structure: 'Cost Structure',
  user_story: 'User Story',
  workpackage: 'Backlog',
  feature: 'Feature',
  use_case: 'Sub-Feature',
  scenario: 'Scenario',
  business_goal: 'Business Goal',
  user_goal: 'User Goal',
  no_goal: 'Non-Goal',
  temp_vendor: 'Vendor',
  data_processing: 'Data Processing',
  ux_ui: 'UI/UX',
  system_integration: 'System Integration',
  transaction_handling: 'Transaction Handling',
  error_handling: 'Error Handling',
  backup_recovery: 'Backup and Recovery',
  usability_and_accessibility: 'Usability and Accessibility',
  scope_to_be_built_in_house: 'Scope to be Built In-House',
  scope_to_be_bought: 'Scope to be Bought',
  scope_using_open_source: 'Scope Using Open Source',
  consumer_protection_compliance: 'Consumer Protection Compliance',
  cross_border_compliance: 'Cross Border Compliance',
  cybersecurity_compliance: 'Cybersecurity Compliance',
  data_compliance: 'Data Compliance',
  financial_crime_compliance: 'Financial Crime Compliance',
  financial_transparency_compliance: 'Financial Transparency Compliance',
  fraud_prevention_compliance: 'Fraud Prevention Compliance',
  governance_compliance: 'Governance Compliance',
  market_conduct_compliance: 'Market Conduct Compliance',
  kyc_compliance: 'KYC Compliance',
  operational_resilience_compliance: 'Operational Resilience Compliance',
  payment_compliance: 'Payment Compliance',
  reporting_compliance: 'Reporting Compliance',
  risk_management_compliance: 'Risk Management Compliance',
  sanctions_compliance: 'Sanctions Compliance',
  sustainability_compliance: 'Sustainability Compliance',
  tax_compliance: 'Tax Compliance',
  aml_compliance: 'AML Compliance',
  anti_bribery_compliance: 'Anti-Bribery Compliance',
  ethics_compliance: 'Ethics Compliance',
};

export const ArtifactFieldLiterals = {
  alignement_business_needs: 'Alignment with Business Need',
  alignment_user_goals: 'Alignment with User Goals',
  overall_compliance: 'Overall Compliance',
  scenario: 'Scenarios',
  core_algorithms: 'Core Logics/Algorithms',
  algorithm_exceptions: 'Algorithm Exceptions and Edge Cases',
  actor: 'User(s)',
  priority_label: 'Priority',
};

export const ArtifactTypeIcons = {
  Default: 'file-text',
  application: 'binoculars',
  feature: 'list-checks',
  user_goal: 'folder-user',
  business_goal: 'folder-star',
  no_goal: 'folder-simple-dashed',
  problem: 'smiley-sad',
  customer_segment: 'chalkboard-teacher',
  key_metric: 'hourglass',
  solution: 'smiley',
  unique_value_proposition: 'sketch-logo',
  unfair_advantage: 'trophy',
  channel: 'broadcast',
  revenue_stream: 'wallet',
  cost_structure: 'receipt',
  actor: 'user',
  authentication: 'fingerprint-simple',
  authorization: 'keyhole',
  data_processing: 'tree-structure',
  ux_ui: 'bezier-curve',
  reporting: 'chart-bar',
  system_integration: 'plugs',
  transaction_handling: 'credit-card',
  error_handling: 'warning',
  backup_recovery: 'backspace',
  usability_and_accessibility: 'eyeglasses',
  security: 'lock-simple',
  reliability: 'boxing-glove',
  performance: 'battery-charging',
  availability: 'door',
  scalability: 'chart-line-up',
  standards: 'certificate',
  technologies: 'cpu',
  architecture: 'house-simple',
  view: 'computer_desktop',
  human_interaction: 'cursor-click',
  front_end_method: 'service',
  back_end_method: 'service', 
  event: 'browsers',
  request_event: 'browsers',
  response_event: 'browsers',
  data_type: 'article',
  ddd_domain: 'book-open',
  customer_journey: 'person-simple-run',
};

export const ArtifactTypeColors = {
  view: 'border-yellow-300',
  human_interaction: 'border-pink-300',
  front_end_method: 'border-blue-300',
  back_end_method: 'border-blue-300',
  response_event: 'border-orange-300',
  request_event: 'border-orange-300',
};

export const artifactFieldIcon = {
  bio: 'user-focus',
  pains: 'smiley-sad',
  needs: 'hands-praying',
  quotes: 'quotes',
  solutions: 'smiley',
  goals: 'folder-user',
};

export const FieldTypeIcons = {
  string: 'book-open-text',
};

export const WorkspaceArtifactTypes = [ArtifactTypes.Application];
export const ApplicationArtifactTypes = Object.keys(ArtifactDataModel).filter(artifactType => !WorkspaceArtifactTypes.includes(artifactType));

export const ArtifactFieldBases = {
  Primitive: 'primitive',
  Artifact: 'artifact',
};

export const AiFileTypes = {
  CoverImage: 'cover_image',
  ActorImage: 'actor_image',
  WireframeFile: 'wireframe_file',
  DesignFile: 'design_file',
  OtherFile: 'other_file',
};

export const noEditableArtifactFields = ['summary', 'version', 'id', 'created_at', 'updated_at', 'ownership_application_id', 'charm'];

export const ArtifactTypeRouteNames = {
  application: RouteNames.Application,
  user_story: RouteNames.UserStory,
  feature: RouteNames.Feature,
  use_case: RouteNames.Usecase,
  customer_journey: RouteNames.CustomerJourney,
  actor: RouteNames.User,
};

export const ArtifactListRouteNames = {
  user_story: RouteNames.UserStories,
  feature: RouteNames.Features,
  actor: RouteNames.Users,
  human_interaction: RouteNames.HumanInteractionList,
  workpackage: RouteNames.Backlog,
  view: RouteNames.Views,
  ddd_domain: RouteNames.Domains,
};

export const RequestStatus = {
  NotStarted: 'not_started',
  Pending: 'pending',
  InProgress: 'in_progress',
  Completed: 'completed',
};

export const DataTypeTypes = {
  Primitive: 'primitive',
  Entity: 'entity',
  Enum: 'enum',
};

export const FieldTypeEditors = {
  [ArtifactFieldBases.Primitive]: {
    string: {
      single: 'MagicTextArea',
      array: 'MagicList',
      priority_label: 'MagicPriority',
    },
  },
  [ArtifactFieldBases.Artifact]: {
    default: {
      single: 'MagicArtifactLink',
      array: 'MagicSortableArtifactList',
    },
    actor: {
      single: 'Actor',
      array: 'MagicActorSelector',
    },
    param: {
      single: 'MagicParam',
      array: 'MagicParamsList',
    },
  },
};
