<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useLanguages from '@/composables_NEW/useLanguages';
import useApplications from '@/composables_NEW/useApplications';
import useWorkspaces from '@/composables_NEW/useWorkspaces';

import AgentTypewriter from '@/components_NEW/agent/AgentTypewriter.vue';
import PresentationPopup from '@/components_NEW/agent/PresentationPopup.vue';
import DocumentationPopup from '@/components_NEW/agent/DocumentationPopup.vue';
import QuotesPopup from '@/components_NEW/agent/QuotesPopup.vue';
import AgentSuggestionCard from '@/components_NEW/agent/AgentSuggestionCard.vue';
import SharePopup from '@/components_NEW/common/SharePopup.vue';
import UpgradePopup from '@/components_NEW/application/UpgradePopup.vue';

import { SubscriptionTypeLimits } from '@/constants/subscription';
import { ApplicationAssetConfigs } from '@/constants/application';
import { RouteNames } from '@/constants/common';
import { ArtifactTypes } from '@/constants/artifacts';

const router = useRouter();
const { t } = useLanguages();

const { activeApplication } = useApplications();
const { activeWorkspace } = useWorkspaces();

const presentationPopupRef = ref();
const documentationPopupRef = ref();
const quotesPopupRef = ref();
const sharePopupRef = ref();
const upgradePopupRef = ref();

const allowExport = computed(() => !!SubscriptionTypeLimits[activeWorkspace.value?.subscription_type].allowExport);

const assetsToDefine = computed(() => {
  const assets = [ArtifactTypes.Actor, ArtifactTypes.Feature].filter(assetType => {
    return !activeApplication.value[assetType]?.length;
  });

  return assets;
});

const agentContents = computed(() => ({
  messages: [
    {
      text: `
        Hello!<br/><br/>
        I've <span class="text-indigo-700">generated contents</span> for your idea exploration.<br/><br/>
        Only you can make it perfect, so please start with the name of your idea and edit from there!
      `,
      condition: assetsToDefine.value.length === 2,
    },
    {
      text: `
        Hello! I'm here to help you<br/>
        Let's continue creating some users for your application.
      `,
      condition: assetsToDefine.value.includes(ArtifactTypes.Actor),
    },
    {
      text: `
        Well done on defining your users! I’ve added them to this Overview.<br/><br/>
        Now, move onto defining features.
      `,
      condition: assetsToDefine.value.includes(ArtifactTypes.Feature),
    },
    {
      text: `
        Good job! Your idea is taking shape...
        Now it’s a good time to include your tech team and think about the next steps!
      `,
      condition: true,
    },
  ],
  suggestions: [
    {
      content: {
        ...ApplicationAssetConfigs[ArtifactTypes.Actor],
        label: 'Define the Users',
        description: 'Consider the different end users of your idea',
        action: () => {
          router.push({
            name: RouteNames.Users,
          });
        },
      },
      condition: assetsToDefine.value.includes(ArtifactTypes.Actor),
    },
    {
      content: {
        ...ApplicationAssetConfigs[ArtifactTypes.Feature],
        label: 'Define Features',
        description: 'Include scenarios of the users interacting with your idea',
        action: () => {
          router.push({
            name: RouteNames.Features,
          });
        },
      },
      condition: assetsToDefine.value.length === 1 && assetsToDefine.value.includes(ArtifactTypes.Feature),
    },
    {
      content: {
        icon: 'users-three',
        label: 'Invite your Tech Team',
        description: 'Share to refine together the use cases, diagrams and user stories',
        action: () => sharePopupRef.value?.open(),
      },
      condition: assetsToDefine.value.length === 0,
    },
    {
      content: {
        icon: 'file-code',
        label: 'Export Documentation',
        description: 'Get all the technical information you have created',
        action: () => {
          if (!allowExport.value) {
            upgradePopupRef.value?.open();
          }
          else {
            documentationPopupRef.value?.open();
          }
        },
      },
      condition: assetsToDefine.value.length === 0,
    },
  ],
}));

const agentText = computed(() => {
  return agentContents.value.messages.find(message => message.condition)?.text;
});

const agentSuggestions = computed(() => {
  const response = agentContents.value.suggestions.filter(suggestion => !!suggestion.condition).map(suggestion => suggestion.content);
  return response;
});
</script>

<template>
  <div class="w-full">
    <AgentTypewriter
      class=" sticky top-0 w-full border-b-indigo-100 bg-cyan-50 p-8 pt-12 pb-4 border-b min-h-40 z-20"
      :agent-text="t(agentText)"
    />
    <div v-if="agentSuggestions" class="flex flex-col p-8">
      <div class="font-semibold pl-4">
        {{ t('What to do next') }}
      </div>
      <div class="flex flex-col mt-2 gap-4">
        <AgentSuggestionCard
          v-for="(props, index) in agentSuggestions"
          :key="index"
          v-bind="props"
        />
      </div>
    </div>
    <PresentationPopup ref="presentationPopupRef"/>
    <DocumentationPopup ref="documentationPopupRef" :share-popup="sharePopupRef"/>
    <QuotesPopup ref="quotesPopupRef"/>
    <SharePopup ref="sharePopupRef"/>
    <UpgradePopup ref="upgradePopupRef"/>
  </div>
</template>
