<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

import useLanguages from '@/composables_NEW/useLanguages';
import useArtifacts from '@/composables_NEW/useArtifacts';
import useApplications from '@/composables_NEW/useApplications';
import useWorkspaces from '@/composables_NEW/useWorkspaces';
import useProgressiveLoad from '@/composables_NEW/useProgressiveLoad';

import Icon from '@/components_NEW/common/Icon.vue';
import ApplicationIcon from '@/components_NEW/application/ApplicationIcon.vue';
import SharePopup from '@/components_NEW/common/SharePopup.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import UpgradePopup from '@/components_NEW/application/UpgradePopup.vue';

import { SubscriptionTypeIcons, SubscriptionTypeUpgrades } from '@/constants/subscription';
import { RouteNames } from '@/constants/common';
import { FormColors, FormStyles } from '@/constants/forms';
import { ArtifactTypeLiterals, ArtifactTypes } from '@/constants/artifacts';
import { isMobile } from '@/utils/utils';

const { t } = useLanguages();
const router = useRouter();

const { artifacts } = useArtifacts();
const { activeWorkspace } = useWorkspaces();
const { activeApplication } = useApplications();
const { artifactsLoading, loadArtifacts } = useProgressiveLoad();

const sharePopupRef = ref();
const upgradePopupRef = ref();

const subscriptionType = computed(() => activeWorkspace.value?.subscription_type);
const subscriptionIcon = computed(() => SubscriptionTypeIcons[subscriptionType.value]);
const allowUpgrade = computed(() => !!SubscriptionTypeUpgrades[activeWorkspace.value?.subscription_type]);
const showLoading = computed(() => artifactsLoading.value.length > 0);

const sectionName = computed(() => {
  const { name, params } = router.currentRoute.value;
  return [RouteNames.Artifact, RouteNames.ArtifactList].includes(name) ? t(ArtifactTypeLiterals[params.artifactType] || params.artifactType) : t(name);
});
const items = computed(() => {
  return [
    {
      name: 'Users',
      icon: 'user',
      color: 'bg-amber-200',
      routeName: RouteNames.Users,
      number: (activeApplication.value?.actor || []).filter(actorId => artifacts[ArtifactTypes.Actor][actorId])?.length || 0,
    }, {
      name: 'Domains',
      icon: 'puzzle-piece',
      color: 'bg-blue-200',
      routeName: RouteNames.Domains,
      number: (activeApplication.value?.ddd_domain || []).filter(domainId => artifacts.ddd_domain[domainId])?.length || 0,
    }, {
      name: 'Features',
      icon: 'list-checks',
      color: 'bg-emerald-200',
      routeName: RouteNames.Features,
      number: (activeApplication.value?.feature || []).filter(featureId => artifacts[ArtifactTypes.Feature][featureId])?.length || 0,
    }, {
      name: 'Glossary',
      icon: 'book-open',
      color: 'bg-violet-200',
      routeName: RouteNames.DataTypes,
      number: (activeApplication.value?.data_type || []).filter(dataTypeId => artifacts.data_type[dataTypeId])?.length || 0,
    },
  ];
});

function onClickSubscriptionButton() {
  if (allowUpgrade.value) {
    upgradePopupRef.value.open();
  }
}

watch(activeApplication, (newVal, oldVal) => {
  if (newVal && newVal.id !== oldVal?.id) {
    loadArtifacts(['actor', 'feature', 'ddd_domain', 'data_type']);
  }
});
</script>

<template>
  <div
    class="
      flex overflow-hidden w-full items-end flex-wrap md:flex-nowrap
      border-b border-slate-200 bg-white px-0 lg:px-8 transition-all duration-500
    "
    :class="[
      activeApplication?.id ? 'delay-1000 max-h-60' : 'max-h-0',
    ]"
  >
    <div class="relative flex-grow max-w-full flex justify-between flex-wrap">
      <div class="flex max-w-full flex-shrink gap-4 items-center h-20 px-4">
        <ApplicationIcon class="flex-shrink-0" :type="activeApplication?.type"/>
        <div class="flex font-semibold flex-col overflow-hidden">
          <div class="leading-4 whitespace-nowrap md:whitespace-normal hover:underline overflow-hidden text-ellipsis cursor-pointer"
            @click="() => {
              router.push({
                name: RouteNames.Application,
                params: {
                  org: router.currentRoute.value.params.org,
                  appId: activeApplication?.id,
                }
              })
            }"
          >{{ activeApplication?.name }}</div>
          <div class="text-xs text-slate-500">{{ t(sectionName) }}</div>
        </div>
      </div>
      <div v-if="showLoading" class="flex items-center justify-center bg-white"
        :class="[
          isMobile() ? 'absolute top-0 left-0 w-full h-12 z-30' : 'relative',
        ]"
      >
        <Icon icon="loading" class="w-8 h-8 animate-spin" />
        <span class="text-sm ml-2">{{ t('Wait, loading data...') }}</span>
      </div>
      <div class="flex-shrink-0 md:w-fit w-full flex h-20 overflow-x-auto">
        <div class="flex flex-shrink-0 px-2 gap-2 items-center cursor-pointer"
          :class="[
            sectionName === name ? 'bg-slate-100' : 'bg-white hover:bg-slate-50',
          ]"
          v-for="{ name, icon, number, color, routeName } in items"
          :key="name"
          @click="() => {
            router.push({
              name: routeName,
              params: {
                org: router.currentRoute.value.params.org,
                appId: router.currentRoute.value.params.appId,
              }
            })
          }"
        >
          <Icon class="p-2 rounded-full pointer-events-none"
            :class="[
              color,
              isMobile() ? 'w-10 h-10 p-0' : 'w-11 h-11 p-2',
            ]"
            :icon="icon"
          />
          <div class="flex flex-col pointer-events-none">
            <div class="text-2xl leading-6">{{ number }}</div>
            <div class="text-xs">{{ t(name) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white flex-shrink-0 w-fit [&_span]:text-slate-500 text-xs font-semibold flex h-20 items-center justify-end ml-2"
      :class="[
        isMobile() ? 'absolute top-0 right-0' : 'relative',
      ]"
    >
      <GlueButton
        :label="isMobile() ? '' : t('Share')"
        icon="users-three"
        :style="FormStyles.Naked"
        :color="FormColors.Indigo"
        @click-button="() => {
          sharePopupRef.open(activeApplication?.id);
        }"
      />
      <GlueButton
        :label="isMobile() ? '' : t(subscriptionType)"
        :icon="subscriptionIcon"
        :style="FormStyles.Naked"
        :color="FormColors.Indigo"
        @click-button="onClickSubscriptionButton"
      />
    </div>
    <SharePopup ref="sharePopupRef"/>
    <UpgradePopup ref="upgradePopupRef"/>
  </div>
</template>
