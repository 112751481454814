import { computed } from 'vue';

import useArtifacts from '@/composables_NEW/useArtifacts';

import { RequestStatus } from '@/constants/artifacts';

const { artifacts, listArtifactsByApplication } = useArtifacts();

const longRunningRequestByArtifactTypeAndId = computed(() => Object.values(artifacts.long_running_request || {}).reduce((acc, curr) => {
  const existentRequest = acc[curr.starting_artifact_name]?.[curr.starting_artifact_id];
  
  acc[curr.starting_artifact_name] = acc[curr.starting_artifact_name] || {};
  acc[curr.starting_artifact_name][curr.starting_artifact_id] = existentRequest && existentRequest.status !== RequestStatus.Completed ? existentRequest : curr;
  return acc;
}, {}));

async function getAllLongRunningRequests(appId) {
  return await listArtifactsByApplication('long_running_request', appId);
}

export default function useLongRunningRequests() {
  return {
    longRunningRequestByArtifactTypeAndId,
    getAllLongRunningRequests,
  };
}
