<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { onClickOutside } from '@vueuse/core';

import useFetching from '@/composables_NEW/useFetching';

import Tooltip from '@/components_NEW/formFields/Tooltip.vue';
import Confirm from '@/components_NEW/formFields/Confirm.vue';
import Icon from '@/components_NEW/common/Icon.vue';

import { FormColors, FormStyles, ButtonSizes, FormThemes, FormBorderStyles, TooltipColors, FormTextAligns } from '@/constants/forms';
import { getNewTestId, isMobile } from '@/utils/utils';

const emit = defineEmits(['clickButton']);

const router = useRouter();

const { fetchingActions, fetchingErrors, removeFetchingError } = useFetching();

const props = defineProps({
  id: String,
  dataType: String,
  label: String,
  icon: String,
  disabled: Boolean,
  checked: Boolean,
  to: Object,
  href: String,
  fetchingAction: String,
  tooltip: String,
  confirmMessage: String,
  forceFetching: Boolean,
  enableOnFetching: Boolean,
  download: String,
  fetchingLabel: String,
  preventShowError: Boolean,
  target: {
    type: String,
    default: '_blank',
  },
  size: {
    type: String,
    default: ButtonSizes.Default,
  },
  textAlign: {
    type: String,
    default: FormTextAligns.Left,
  },
  borderStyle: String,
  style: String,
  color: String,
});

const tooltipRef = ref();
const confirmRef = ref();
const buttonDom = ref();
const timeout = ref();

const buttonStyle = computed(() => props.style || (props.label ? FormStyles.Solid : FormStyles.Naked));
const buttonColor = computed(() => props.color || (props.label ? FormColors.Indigo : FormColors.Black));
const buttonBorderStyle = computed(() => props.borderStyle || (props.label ? FormBorderStyles.Straight : FormBorderStyles.RoundedFull));
const dataTest = computed(() => getNewTestId(props.id || (`${props.label || props.icon}${props.label || props.icon ? '-' : ''}button`)));

const labelString = computed(() => {
  const defaultLabel = props.label || '';
  return showWaiting.value ? props.fetchingLabel || defaultLabel : defaultLabel;
});

const isFetching = computed(() => {
  return props.fetchingAction && fetchingActions.value.has(props.fetchingAction);
});

const error = computed(() => {
  return !props.preventShowError && props.fetchingAction && fetchingErrors.value[props.fetchingAction];
});

const showWaiting = computed(() => {
  return props.forceFetching || (isFetching.value && !error.value);
});

onClickOutside(buttonDom, () => {
  hideTooltip();
});

function onClickButton(ev) {
  ev.stopPropagation();
  clearError();

  if (props.disabled || (isFetching.value && !props.enableOnFetching)) {
    ev.preventDefault();
    return false;
  } else if (props.confirmMessage) {
    confirmRef.value.show();
  } else {
    callAction();
  }
}

function callAction() {
  emit('clickButton');
  if (props.to) {
    const {
      name,
      params = {},
    } = props.to;

    router.push({name, params});
  }
}

function showTooltip() {
  const message = error.value || props.tooltip;

  if (tooltipRef.value && message) {
    tooltipRef.value.show(message, {top: 0, left: buttonDom.value.offsetWidth / 2});
  }
}

function clearError() {
  removeFetchingError(props.fetchingAction);
}

function hideTooltip() {
  clearTimeout(timeout.value);
  if (tooltipRef.value && !error.value) {
    tooltipRef.value.hide();
  }
}

function onMouseOver() {
  timeout.value = setTimeout(() => {
    showTooltip();
  }, 800);    
}

watch(error, (newVal) => {
  if (newVal) {
    showTooltip();
  } else {
    hideTooltip();
  }
});
</script>

<template>
  <div class="flex transition-all duration-300 ease-in-out"
    :class="[
      disabled ? 'cursor-default pointer-events-none' : 'cursor-pointer',
      disabled ? FormThemes[FormColors.Disabled][buttonStyle] : error ? FormThemes[FormColors.Red][buttonStyle] : FormThemes[buttonColor][buttonStyle],
      buttonBorderStyle,
    ]"
  >
    <Confirm
      ref="confirmRef"
      v-if="confirmMessage"
      :data-type="dataType"
      :message="confirmMessage"
      @confirm="callAction"
    />
    <a
      ref="buttonDom"
      :data-test="dataTest"
      :disabled="disabled || showWaiting"
      :download="download"
      :data-ui="[
        checked ? 'checked' : '',
      ]"
      class="font-medium transition-all duration-150 ease-in-out flex items-center relative w-full box-border"
      :class="[
        textAlign === FormTextAligns.Left && 'justify-start',
        textAlign === FormTextAligns.Right && 'justify-end',
        textAlign === FormTextAligns.Center && 'text-center justify-center',
      ]"
      @click="onClickButton"
      @mouseenter="onMouseOver"
      @mouseleave="hideTooltip"
      :href="props.href"
      :target="props.target"
    >
      <Tooltip
        ref="tooltipRef"
        :close-button="!!error"
        :color="!!error ? TooltipColors.Red : TooltipColors.Black"
        @clickCloseButton="clearError"
      />
      <div
        class="items-center max-h-full flex min-w-min w-full"
        :class="[
          showWaiting && 'opacity-50',
          size !== ButtonSizes.Custom && 'p-1',
          size === ButtonSizes.Mini && 'min-w-5 min-h-5 p-0.5 gap-1',
          size === ButtonSizes.Default && 'min-w-8 min-h-8 p-1 gap-2',
          size === ButtonSizes.Big && 'min-w-10 min-h-10 gap-0',
          textAlign === FormTextAligns.Center && 'justify-center',
        ]"
      >
        <Icon
          :icon="icon"
          class="flex-grow-0 flex-shrink-0"
          :class="[
            showWaiting && 'opacity-50',
            size === ButtonSizes.Mini && 'h-4 w-4',
            size === ButtonSizes.Default && 'w-6 h-6',
            size === ButtonSizes.Big && 'h-8 w-8',
            textAlign === FormTextAligns.Right && 'order-2',
          ]"
        />
        <span
          v-if="label"
          class="px-2 first-letter:capitalize flex items-center min-w-min text-left"
          :class="[
            isMobile() ? 'text-sm' : '',
            textAlign === FormTextAligns.Left && 'justify-start text-left',
            textAlign === FormTextAligns.Right && 'justify-end text-right',
            textAlign === FormTextAligns.Center && 'justify-center text-center',
          ]"
          v-html="labelString"
        />
        <slot></slot>
      </div>
      <div
        v-if="showWaiting"
        class="flex items-center justify-center absolute inset-0"
      >
        <Icon
          icon="loading"
          class="min-w-8 w-full max-w-12 min-h-8 h-full max-h-12"
        />
      </div>
    </a>
  </div>
</template>
