import pako from 'pako';
import { getOriginPrivateDirectory } from 'native-file-system-adapter';
import cacheAdapter from 'native-file-system-adapter/src/adapters/cache.js';

import { logError } from '@gluecharm/glue-charm-library';

async function savePersistentData(id, dataObj) {
  try {
    const compressedData = pako.gzip(new TextEncoder().encode(JSON.stringify(dataObj)));
    const root = await getOriginPrivateDirectory(cacheAdapter);
    const fileHandle = await root.getFileHandle(`${id}.zip`, { create: true });
    const writable = await fileHandle.createWritable();
  
    await writable.write(compressedData);
    await writable.close();
    
    return true;
  } catch (error) {
    logError(`error saving persistent data ${id}: `, error);
    return false;
  }
}

async function getPersistentData(id) {
  try {
    const root = await getOriginPrivateDirectory(cacheAdapter);
    const fileHandle = await root.getFileHandle(`${id}.zip`, { create: true });
    const file = await fileHandle.getFile();
    const contentsBuffer = await file.arrayBuffer();
    const decompressedData = pako.ungzip(new Uint8Array(contentsBuffer));
    const decoder = new TextDecoder();
    const contents = decoder.decode(decompressedData);

    return contents && JSON.parse(contents);
    
  } catch (error) {
    logError(`error getting persistent data ${id}: `, error);
    return false;
  }
}

async function removePersistentData(id) {
  try {
    const root = await getOriginPrivateDirectory(cacheAdapter);
    await root.removeEntry(`${id}.zip`);

    return true;
    
  } catch (error) {
    logError(`error removing persistent data ${id}: `, error);
    return false;
  }
}

async function removeAllPersistentData() {
  try {
    const root = await getOriginPrivateDirectory(cacheAdapter);

    for await (const handle of root.values()) {
      if (handle.kind === 'file') {
        await root.removeEntry(handle.name);
      }
    }
    
    return true;
  } catch (error) {
    logError('error removing all persistent data: ', error);
    return false;
  }
}

export default function usePersistentData() {
  return {
    savePersistentData,
    getPersistentData,
    removePersistentData,
    removeAllPersistentData,
  };
}
