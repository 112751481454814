<script setup>
import { computed, defineAsyncComponent } from 'vue';

import { filterArtifactFields } from '@/utils/artifactUtils';
import { ArtifactDataModel, ArtifactFieldBases, FieldTypeEditors } from '@/constants/artifacts';
import { getHumanReadableField } from '@/utils/utils';

const restrictedFields = ['artifact_name', 'artifact_item_id'];

const props = defineProps({
  artifactType: String,
  artifactId: String,
});

const primitiveFields = computed(() => filterArtifactFields(props.artifactType, {
  base: ArtifactFieldBases.Primitive,
}).filter(field => !restrictedFields.includes(field)).map(field => {
  const fieldModel = ArtifactDataModel[props.artifactType].fields[field];
  const editorName = FieldTypeEditors[ArtifactFieldBases.Primitive][fieldModel.type][fieldModel._is_array ? 'array' : 'single'];
  return {
    field,
    label: getHumanReadableField(field),
    editor: defineAsyncComponent(() => import(`@/components_NEW/magicFields/${editorName}.vue`)),
  };
}));
</script>

<template>
  <div class="w-full flex flex-col gap-4 mt-20 p-4">
    <div v-for="{field, editor, label} in primitiveFields" :key="field">
      <component
        :is="editor"
        :label="label"
        :artifactType="props.artifactType"
        :artifactField="field"
        :artifactId="props.artifactId"
      />
    </div>
  </div>
</template>