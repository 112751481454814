import { ref, watch } from 'vue';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import usePersistentData from '@/composables_NEW/usePersistentData';
import useApplications from '@/composables_NEW/useApplications';
import useArtifacts from '@/composables_NEW/useArtifacts';

import { ApplicationArtifactTypes } from '@/constants/artifacts';

const { fetchGlueCharmMethod } = useGlueCharmServices();
const { savePersistentData } = usePersistentData();
const { activeApplication } = useApplications();
const { artifacts, editedArtifacts, saveLocalArtifactChanges, cleanArtifactsByType, setArtifacts } = useArtifacts();

const loadedArtifacts = ref([]);
const artifactsToLoadOnLoadApplication = ref([]);
const artifactsLoading = ref([]);

function updateApplicationPersistentData() {
  const newPersistentData = ApplicationArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = Object.values(artifacts[artifactType]);
    return acc;
  }, {});
  savePersistentData(activeApplication.value.id, newPersistentData);
}

async function loadArtifacts(artifactTypes) {
  if (!activeApplication.value?.id) {
    artifactsToLoadOnLoadApplication.value = [
      ...artifactsToLoadOnLoadApplication.value,
      ...artifactTypes.filter(artifactType => !artifactsToLoadOnLoadApplication.value.includes(artifactType)),
    ];
    return;
  }

  const artifactsToLoad = artifactTypes.filter(artifactType => !loadedArtifacts.value.includes(artifactType) && !artifactsLoading.value.includes(artifactType));

  if (artifactsToLoad.length > 0) {
    artifactsLoading.value.push(...artifactsToLoad);

    const allLoaded = await fetchGlueCharmMethod('mass-get-generic-entities', `load-artifacts-${activeApplication.value.id}`, {
      ownership_application_id: activeApplication.value.id,
      generation_version: 'v0',
      entity_names: artifactsToLoad,
    }, 'gluex');

    artifactsLoading.value = artifactsLoading.value.filter(artifactType => !artifactsToLoad.includes(artifactType));

    if (allLoaded?.success) {
      const responseArtifacts = allLoaded?.data?.entities;
      console.log(`responseArtifacts:`, responseArtifacts);
      
      Object.keys(responseArtifacts).forEach(artifactType => {
        Object.keys(editedArtifacts[artifactType] || {}).forEach(artifactId => {
          const responseArtifact = responseArtifacts[artifactType].find(item => item.id === artifactId);
          const editedArtifact = editedArtifacts[artifactType][artifactId];
          if (responseArtifact?.version === editedArtifact?.version) {
            responseArtifacts[artifactType] = responseArtifacts[artifactType].map(item => item.id === artifactId ? artifacts[artifactType][artifactId] : item);
            saveLocalArtifactChanges(artifactType, artifactId);
          }
        });
        cleanArtifactsByType(artifactType);
        setArtifacts(artifactType, responseArtifacts[artifactType]);
        loadedArtifacts.value.push(artifactType);
      });
  
      updateApplicationPersistentData();
    }
  }
}

watch(activeApplication, (newVal, oldVal) => {
  if (newVal && newVal.id !== oldVal?.id) {
    loadedArtifacts.value = [];
    loadArtifacts(artifactsToLoadOnLoadApplication.value);
    artifactsToLoadOnLoadApplication.value = [];
  }
});

export default function useProgressiveLoad() {
  return {
    loadArtifacts,
    artifactsLoading,
  };
}