import { ref, readonly } from 'vue';

const selectedArtifact = ref(null);

function openArtifactForm(artifactType, artifactId) {
  selectedArtifact.value = {
    artifactType,
    artifactId,
  };
}

function closeArtifactForm() {
  selectedArtifact.value = null;
}

export default function useAgentSidebar () {
  return {
    selectedArtifact: readonly(selectedArtifact),
    openArtifactForm,
    closeArtifactForm,
  };
}