<script setup>
import { computed, ref, watch } from 'vue';

import useApplications from '@/composables_NEW/useApplications';
import useAgentSidebar from '@/composables_NEW/useAgentSidebar';
import useLanguages from '@/composables_NEW/useLanguages';

import GenericArtifactForm from '@/components_NEW/forms/GenericArtifactForm.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import AgentSidebarSuggestions from '@/components_NEW/agent/AgentSidebarSuggestions.vue';
import AgentSidebarNotes from '@/components_NEW/agent/AgentSidebarNotes.vue';
import AgentSidebarExport from '@/components_NEW/agent/AgentSidebarExport.vue';

import { ArtifactTypeIcons } from '@/constants/artifacts';
import { getHumanReadableArtifactType } from '@/utils/utils';

import { ButtonSizes, FormColors, FormStyles } from '@/constants/forms';
import { isMobile } from '@/utils/utils';
import { SidebarSizes } from '@/constants/common';

const ArtifactFormComponents = {
  Default: GenericArtifactForm,
};

const DefaultItems = [
  {
    image: '/agent.png',
    label: 'Assistant',
    component: AgentSidebarSuggestions,
  },
  {
    icon: 'notepad',
    label: 'Notes',
    component: AgentSidebarNotes,
  },
  {
    icon: 'arrow-up-right',
    label: 'Export',
    component: AgentSidebarExport,
  },
];

const { t } = useLanguages();
const { activeApplication } = useApplications();
const { selectedArtifact, closeArtifactForm } = useAgentSidebar();

const itemSelectedIndex = ref();
const sidebarSize = ref(SidebarSizes.Small);  

const items = computed(() => [
  ...DefaultItems,
  ...(selectedArtifact.value ? [{
    component: ArtifactFormComponents[selectedArtifact.value.artifactType] || ArtifactFormComponents.Default,
    icon: ArtifactTypeIcons[selectedArtifact.value.artifactType] || ArtifactTypeIcons.Default,
    label: getHumanReadableArtifactType(selectedArtifact.value.artifactType),
    props: {
      artifactType: selectedArtifact.value.artifactType,
      artifactId: selectedArtifact.value.artifactId,
    },
  }] : []),
]);

const itemSelected = computed(() => items.value[itemSelectedIndex.value]);  

const contentStyle = computed(() => {
  const openedWidth = sidebarSize.value === SidebarSizes.Large ? '1024px' : '490px';

  if (isMobile()) {
    return {
      width: '100%',
      maxHeight: itemSelected.value?.component ? '100%' : '0',
    };
  }
  return {
    width: itemSelected.value?.component ? openedWidth : '0',
    height: '100%',
  };
});

function onClickCloseButton() {
  itemSelectedIndex.value = null;
  closeArtifactForm();
}

function onClickExpandButton() {
  sidebarSize.value = sidebarSize.value === SidebarSizes.Large ? SidebarSizes.Small : SidebarSizes.Large;
}

watch(selectedArtifact, (newArtifact) => {
  if (newArtifact) {
    itemSelectedIndex.value = DefaultItems.length;
  }
});
</script>

<template>
  <div v-if="activeApplication" class="flex justify-end pointer-events-none"
    :class="[
      isMobile() && 'fixed bottom-0 left-0 right-0 w-full flex-col',
      !isMobile() && 'absolute right-0 h-full max-w-full',
      sidebarSize === SidebarSizes.Small && 'xl:relative',
    ]"
  >
    <div class="flex-shrink-0 bg-slate-200 flex items-center gap-4 pointer-events-auto"
      :class="[
        isMobile() ? 'flex-row w-full h-16 px-2' : 'flex-col w-16 h-full py-2',
      ]"
    >
      <div class="flex flex-col gap-1 items-center"
        v-for="({icon, image, label }, index) in items"
        :key="icon"
      >
        <GlueButton
          class="w-12 h-12 rounded-full bg-white transition-all border-white"
          :class="[
            itemSelectedIndex === index ? 'scale-105 drop-shadow-lg border-2' : 'hover:scale-105 hover:drop-shadow-lg',
            icon && '[&_svg]:p-2.5',
          ]"
          :icon="icon"
          :color="FormColors.Cyan"
          :checked="itemSelectedIndex === index"
          :size="ButtonSizes.Custom"
          @clickButton="() => {
            itemSelectedIndex = itemSelectedIndex === index ? null : index;
          }"
        >
          <img v-if="image" :src="image" alt="" class="w-12"/>
        </GlueButton>
        <div v-if="!isMobile()" class="text-xs text-center"
          :class="[
            itemSelectedIndex === index ? 'text-cyan-600 font-semibold' : 'text-slate-500',
          ]"
        >{{ label }}</div>
      </div>
    </div>
    <div class="relative pointer-events-auto bg-white flex-shrink flex flex-col items-start overflow-hidden overflow-y-auto transition-all border-l-indigo-100 border-l"
      :style="contentStyle"
    >
      <div class="sticky top-8 h-0 w-full px-4 flex items-center justify-between z-50"
        :class="[
          itemSelected ? 'opacity-100' : 'opacity-0 pointer-events-none',
        ]"
      >
        <GlueButton
          :icon="sidebarSize === SidebarSizes.Large ? 'sidebar-collapse' : 'sidebar-expand'"
          :tooltip="t(sidebarSize === SidebarSizes.Large ? 'Collapse' : 'Expand')"
          :color="FormColors.White"
          :style="FormStyles.Solid"
          @clickButton="onClickExpandButton"
        />
        <span class="text-lg font-semibold text-indigo-700">{{ itemSelected?.label }}</span>
        <GlueButton
          icon="x_icon"
          :tooltip="t('Close')"
          :color="FormColors.White"
          :style="FormStyles.Solid"
          @clickButton="onClickCloseButton"
        />
      </div>
      <Transition name="fade" mode="out-in" duration="150">
        <component v-if="itemSelected" :is="itemSelected.component" :key="itemSelectedIndex" v-bind="itemSelected.props" />
      </Transition>
    </div>
  </div>
</template>
